// Base Fabric like cotton, silk

import * as React from "react"
import CTA from "../components/CTA"
import FabricInfo from "../components/FabricInfo"
import Layout from "../layout/Layout"
import Reel from "../components/Reel"
import SEO from "../components/SEO"
import { graphql, navigate } from "gatsby"
import "./fabricBase.scss"
import ReactMarkdown from "react-markdown"

const FabricsMadeFrom = ({ data, pageTitle }) => {
  const fabrcisFrom = data.allMarkdownRemark.edges?.length
    ? data.allMarkdownRemark.edges.map((i) => ({
        name: i.node.frontmatter.title,
        slug: i.node.fields.slug,
        img: i.node.frontmatter.thumbnail,
      }))
    : []

  if (fabrcisFrom.length > 0) {
    return (
      <div id="checkout">
        <h3>Checkout fabrics made from {pageTitle}</h3>
        <Reel items={fabrcisFrom} showAsTiles />
      </div>
    )
  }
  return null
}

const FabricBaseTemplate = (props) => {
  const { data } = props
  const fabricBase = data.markdownRemark.frontmatter
  const siteTitle = data.site.siteMetadata?.title || `Fabricsthan`
  const pageTitle = fabricBase.title
  return (
    <Layout bgAlt={siteTitle}>
      <SEO
        title={` ${pageTitle} | ${siteTitle} | Fabrics Of India`}
        description={fabricBase.meta_description}
        image={fabricBase.thumbnail}
        pageContext={props.pageContext}
      />
      <div id="fabric-base-page" className="container">
        <div>
          <p className="sub-heading">{fabricBase.fabric_type} Fabrics</p>
          <h1>{pageTitle}</h1>
        </div>
        <div className="flex-container">
          <div>
            <FabricInfo data={fabricBase} />
          </div>
          {fabricBase?.thumbnail && (
            <div className="image-container fabric-sample">
              <img src={fabricBase.thumbnail} alt={pageTitle} />
            </div>
          )}
        </div>
        <div className="flex-container history-of">
          <div>
            {fabricBase.description && (
              <div className="text-container">
                <h2>{fabricBase.description_title ?? `Description`}</h2>
                <ReactMarkdown>{fabricBase.description}</ReactMarkdown>
              </div>
            )}
            {fabricBase.block_1_text && (
              <div className="text-container">
                <h2>{fabricBase.history_title ?? `History of ${pageTitle}`}</h2>
                <ReactMarkdown>{fabricBase.block_1_text}</ReactMarkdown>
              </div>
            )}
          </div>
          <div className="image-container">
            {fabricBase.description_image && (
              <img
                src={fabricBase.description_image}
                alt={`${fabricBase.title} Description`}
              />
            )}
          </div>
        </div>
        <div className="flex-container photos">
          <div className="photos-container">
            <h2>Photos</h2>
            <div className="images-container">
              {fabricBase.photo_1 && (
                <div className="image-container">
                  <img src={fabricBase.photo_1} alt={`${fabricBase.title} 1`} />
                </div>
              )}
              {fabricBase.photo_2 && (
                <div className="image-container">
                  <img src={fabricBase.photo_2} alt={`${fabricBase.title} 2`} />
                </div>
              )}
              {fabricBase.photo_3 && (
                <div className="image-container">
                  <img src={fabricBase.photo_3} alt={`${fabricBase.title} 3`} />
                </div>
              )}
            </div>
          </div>

          <div>
            <div id="fabric-type-cta">
              <h4>
                {pageTitle} is a {fabricBase.fabric_type} fabric.
              </h4>
              <p>
                Read more about {fabricBase.fabric_type} fabrics like{" "}
                {fabricBase.title === "Jute" ? "Cotton" : "Jute"} and Hemp.
              </p>
              <button
                onClick={() =>
                  navigate(`/type/${fabricBase.fabric_type.toLowerCase()}`)
                }
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="flex-container history-of">
          <div>
            {fabricBase.block_2_text && (
              <div className="text-container">
                <h2>{fabricBase.block_2_title ?? "Process"}</h2>
                <ReactMarkdown>{fabricBase.block_2_text}</ReactMarkdown>
              </div>
            )}
          </div>
          <div className="image-container">
            {fabricBase.block_2_image && (
              <img
                src={fabricBase.block_2_image}
                alt={`${fabricBase.title} Creation Process`}
              />
            )}
          </div>
        </div>
        {pageTitle && <FabricsMadeFrom pageTitle={pageTitle} data={data} />}
        <CTA
          heading="Want to see fabrics from the states in India?"
          buttonLabel="Fabrics Of India"
          buttonLink="/fabrics-of-india"
        />
      </div>
    </Layout>
  )
}
export default FabricBaseTemplate

export const pageQuery = graphql`
  query FabricBasePost(
    $id: String!
    $title: String
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    # Fetch all fabrics that have this as the base fabric
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/fabric/.*.md/" }
        frontmatter: { fabric_base: { in: [$title] } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
            fabric_base
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        care
        care_addtional
        description
        description_title
        description_image
        fabric_type
        block_1_title
        block_1_text
        block_2_title
        block_2_text
        block_2_image
        photo_1
        photo_2
        photo_3
        max_price
        min_price
        popularity
        production
        source
        texture
        thumbnail
        title
        usecase
        weather
        weather_additional
        meta_description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
